import React from 'react';
import PropTypes from 'prop-types';
import of from 'await-of';
import { Map, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { boundsOfCity } from '#utils/utils';
import { generateMarker } from '#components/maps/extras/markers/markers';
import { roAPI } from '#utils/axiosAPI';
import styles from './selectCoordinateMap.module.css';

const targetIcon = generateMarker({ icon: 'target' });
const poiIcon = generateMarker({ icon: 'poi' });

const SelectCoordinateMap = ({ onCancel, onAccept, showButtons, city, cities, height, initialPos, poi, showShadow }) => {
  const [map, setMap] = React.useState(() => {
    const { center, bounds } = boundsOfCity(cities, city);
    return {
      zoom: 12,
      position: center,
      bounds,
    };
  });

  const [position, setPosition] = React.useState(null);

  const searchPlace = React.useCallback(async () => {
    const [resp, error] = await of(
      roAPI.get('/places/reverse', {
        params: {
          query: `${position[0]},${position[1]}`,
        },
      }),
    );
    if (!error) {
      onAccept({ ...resp, position });
    }
  }, [onAccept, position]);

  React.useEffect(() => {
    const { center, bounds } = boundsOfCity(cities, city);
    setMap({
      position: center,
      zoom: 12,
      bounds,
    });
  }, [city, cities]);

  React.useEffect(() => {
    if (!showButtons && position) {
      searchPlace();
    }
  }, [position, showButtons, searchPlace]);

  const handleClick = event => setPosition([event.latlng.lat, event.latlng.lng]);

  return (
    <div data-testid="LeafletCoordinateMap" className={`${showShadow && 'mirai-shadow'} ${styles['wrapper']}`}>
      <div className={styles['map-wrapper']} style={{ height }}>
        <Map
          center={map.position}
          zoom={map.zoom}
          maxZoom={18}
          animate
          bounds={map.bounds}
          className={styles['leaflet-container']}
          onclick={handleClick}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {initialPos && !position && <Marker position={initialPos} title="Ubicación" icon={poi ? poiIcon : targetIcon} />}
          {position && <Marker position={position} title="Ubicación" icon={poi ? poiIcon : targetIcon} />}
        </Map>
      </div>

      {showButtons && (
        <div className={styles['buttons']}>
          <button type="button" className="btn btn-light" onClick={onCancel}>
            Cancelar
          </button>
          <button type="button" className="btn btn-une-g" disabled={!position} onClick={searchPlace}>
            Confirmar ubicación
          </button>
        </div>
      )}
    </div>
  );
};

SelectCoordinateMap.propTypes = {
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
  showButtons: PropTypes.bool,
  city: PropTypes.string,
  cities: PropTypes.array,
  height: PropTypes.string,
  initialPos: PropTypes.arrayOf(PropTypes.string),
  poi: PropTypes.bool,
  showShadow: PropTypes.bool,
};

SelectCoordinateMap.defaultProps = {
  onCancel: f => f,
  onAccept: f => f,
  showButtons: true,
  city: '1',
  cities: [],
  height: '15rem',
  poi: false,
  showShadow: true,
};

export default SelectCoordinateMap;
