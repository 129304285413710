import React from 'react';
import PropTypes from 'prop-types';
import ModalBase from '../modalBase/modalBase';
import { isMobile } from 'react-device-detect';
import { isIFrame } from '#utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faStreetView } from '@fortawesome/free-solid-svg-icons';
import styles from './infoRP.module.css';
import StreetMapsLink from '../../random/streetMapsLink/streetMapLink';

const InfoRPModal = ({ showModal, toggleModal, rp }) => {
  return (
    <div>
      <ModalBase
        show={showModal}
        closeModal={toggleModal}
        headerStyle={{ paddingTop: '0' }}
        cardStyle={isMobile ? {} : { width: isIFrame() ? '40%' : '30%' }}
      >
        <div className="row">
          <div className="col-12">
            <p className={styles['header']}>
              <FontAwesomeIcon icon={faInfoCircle} />
              <span>Información de {rp.type === 1 ? 'Centro de recarga' : 'Centro de credencialización'}</span>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="item-desc">
              <span className="subtitle">Nombre</span>
              <span className="value">{rp.name}</span>
            </div>
          </div>
          <div className="col-12">
            <div className="item-desc">
              <span className="subtitle">Dirección</span>
              <span className="value">
                <StreetMapsLink
                  customClass={styles['link']}
                  text={
                    <>
                      <FontAwesomeIcon icon={faStreetView} style={{ marginRight: '.5rem' }} />{' '}
                      <span>{rp.address}</span>
                    </>
                  }
                  lat={rp.latitude}
                  lng={rp.longitude}
                />
              </span>
            </div>
          </div>
          <div className="col-12">
            <div className="item-desc">
              <span className="subtitle">Descripción</span>
              <span className="value">{rp.description}</span>
            </div>
          </div>
          {rp.image && (
            <div className="col-12">
              <div className={styles['image-wrapper']}>
                <img src={`${process.env.REACT_APP_IMG_RP}/${rp.image}`} alt="RP" />
              </div>
            </div>
          )}
        </div>
      </ModalBase>
    </div>
  );
};

InfoRPModal.propTypes = {
  showModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  rp: PropTypes.any,
};

InfoRPModal.defaultProps = {
  showModal: false,
  toggleModal: f => f,
  rp: {},
};

export default InfoRPModal;
