import store from '#redux/store';
import uuid4 from 'uuid/v4';
import { alertDefault } from '#redux/reducers';
import errors from '#utils/customErrors.json';

export function dispatchByErrorType(type) {
  store.dispatch({
    type: 'ALERT_ADD',
    payload: {
      ...alertDefault,
      type: 'danger',
      uuid: uuid4(),
      title: 'Error',
      message: errors[type] || errors['default'],
    }
  });
}