import React from 'react';
import PropTypes from 'prop-types';
import GoogleLogin from 'react-google-login';

const GoogleAuth = ({ text, onGoogleLogin }) => {
  return (
    <GoogleLogin
      clientId="303288515948-i4c74siidih37dhf6mh5ecgf1eofjkj6.apps.googleusercontent.com"
      buttonText={text}
      className="btn btn-google"
      onSuccess={onGoogleLogin}
      onFailure={onGoogleLogin}
    />
  );
};

GoogleAuth.propTypes = {
  text: PropTypes.string,
  onGoogleLogin: PropTypes.func,
};

GoogleAuth.defaultProps = {
  text: 'Continuar con Facebook',
  onGoogleLogin: f => f,
};

export default GoogleAuth;
