import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import styles from './mapButtons.module.css';

const MapButtons = ({ disableButtons, buttons, map }) => {
  return (
    <div data-testid="MapButtons" className={isMobile ? styles['buttons-wrapper-mobile'] : styles['buttons-wrapper']}>
      {buttons.map((button, i) => (
        <button
          key={i}
          className={`${button.class || 'btn btn-primary'} ${styles['button']} ${button.fullWidth && styles['full-width']}`}
          onClick={() => button.onClick(map)}
          disabled={disableButtons}
          title={button.title}
        >
          {button.label}
        </button>
      ))}
    </div>
  );
};

MapButtons.propTypes = {
  disableButtons: PropTypes.bool,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      class: PropTypes.string,
      onClick: PropTypes.func,
      fullWidth: PropTypes.bool,
      title: PropTypes.string,
    }),
  ),
};

MapButtons.defaultProps = {
  disableButtons: false,
  buttons: [],
};

export default MapButtons;
