import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import styles from './mapCardHeader.module.css';

const logo = require('#assets/images/logo.png');

const MapCardHeader = ({ onBtnClick, disableButtons, title, subtitle }) => {
  return (
    <div data-testid="MapCardHeader" className={styles['header']}>
      <button data-testid="button" type="button" className={`btn btn-link ${styles['header-btn']}`} onClick={onBtnClick} disabled={disableButtons}>
        <FontAwesomeIcon icon={faBars} />
      </button>
      <img src={logo} alt="Logo UNE" className={styles['header-logo']} />
      <div className={styles['header-text']}>
        <span><strong>{title}</strong></span>
        <span>{subtitle}</span>
      </div>
    </div>
  );
};

MapCardHeader.propTypes = {
  onBtnClick: PropTypes.func,
  disableButtons: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MapCardHeader.defaultProps = {
  onBtnClick: f => f,
  disableButtons: false,
  title: '',
  subtitle: '',
};

export default MapCardHeader;
