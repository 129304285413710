export function boundsOfCity(cities, city) {
  const c = cities.find(x => x.id_city === city);
  if (c) {
    const ne = [parseFloat(c.ne_latitude), parseFloat(c.ne_longitude)];
    const sw = [parseFloat(c.sw_latitude), parseFloat(c.sw_longitude)];
    return {
      center: [(ne[0] + sw[0]) / 2, (ne[1] + sw[1]) / 2],
      bounds: [ne, sw],
    };
  }

  const defaultCity = cities[0];
  if (defaultCity) {
    const ne = [parseFloat(defaultCity.ne_latitude), parseFloat(defaultCity.ne_longitude)];
    const sw = [parseFloat(defaultCity.sw_latitude), parseFloat(defaultCity.sw_longitude)];
    return {
      center: [(ne[0] + sw[0]) / 2, (ne[1] + sw[1]) / 2],
      bounds: [ne, sw],
    };
  }

  return {
    center: [0, 0],
    bounds: null,
  };
}

export function decodeSRID(polylinea = '') {
  const points = [];

  const inicio = polylinea.indexOf('((') + 1;
  const fin = polylinea.indexOf(')');
  const texto = polylinea.substring(inicio, fin);

  const array = texto.split(', ');
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    const punto = element.split(' ');

    if (punto.length > 1) {
      const latitude = Number(punto[1]);
      const longitude = Number(punto[0]);

      if (Number.isFinite(latitude) && Number.isFinite(longitude)) {
        points.push([latitude, longitude]);
      }
    }
  }

  return points;
}

export function cardinalFromDegrees(d) {
  const j = (parseInt(d, 10) + 11.25) % 360;
  if (j <= 22.5) return 'Norte';
  else if (j <= 45) return 'Norte / Noroeste';
  else if (j <= 67.5) return 'Noroeste';
  else if (j <= 90) return 'Este / Noroeste';
  else if (j <= 112.5) return 'Este';
  else if (j <= 135) return 'Este / Sureste';
  else if (j <= 157.5) return 'Sureste';
  else if (j <= 180) return 'Sur / Sureste';
  else if (j <= 202.5) return 'Sur';
  else if (j <= 225) return 'Sur / Suroeste';
  else if (j <= 247.5) return 'Suroeste';
  else if (j <= 270) return 'Oeste / Suroeste';
  else if (j <= 292.5) return 'Oeste';
  else if (j <= 315) return 'Oeste / Noroeste';
  else if (j <= 337.5) return 'Noroeste';
  else return 'Norte / Noroeste';
}

export function isIFrame() {
  return window.location !== window.parent.location;
}

export const information_types = [
  { id: 1, name: 'Prepago' },
  { id: 2, name: 'Estudiantes' },
  { id: 3, name: 'Tercera Edad' },
  { id: 4, name: 'Personas con discapacidad' },
];
