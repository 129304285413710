import React from 'react';
import PropTypes from 'prop-types';
import of from 'await-of';
import styles from './formRegister.module.css';
import Input from '../../Input';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { roAPI } from '#utils/axiosAPI';
import { useForm } from 'react-hook-form';

const FormRegister = ({ onAfterSubmit, alert }) => {
  const [terminos, setTerminos] = React.useState(false);
  const toggleTerminos = () => setTerminos(prevState => !prevState);

  const { register, handleSubmit, formState: { errors }, watch } = useForm();

  const onSubmit = async (values) => {
    const [, error] = await of(
      roAPI.post('/auth/register', {
        email: values.email,
        password: values.password,
        name: values.name,
        last_name: values.last_name,
      }),
    );
    if (!error) {
      onAfterSubmit('/auth/inicio', 'redirect');
      alert({
        type: 'success',
        title: '¡Estas a un solo paso!',
        message:
          'Antes de iniciar sesión es necesario que verifiques tu correo. Por favor revisa tu bandeja de entrada.',
      });
    }
  }

  return (
    <div data-testid="FormLogin">
      <form id="formLogin" onSubmit={handleSubmit(onSubmit)} className={`row g-3 ${styles.form}`}>
        <div className="col-12">
          <Input
            label="Nombre"
            placeholder="Nombre"
            type="text"
            {...register('name', { required: 'Este campo es requerido' })}
            error={errors.name}
          />
        </div>

        <div className="col-12">
          <Input
            label="Apellidos"
            placeholder="Apellidos"
            type="text"
            {...register('last_name', { required: 'Este campo es requerido' })}
            error={errors.last_name}
          />
        </div>

        <div className="col-12">
          <Input
            label="Correo electrónico"
            placeholder="Correo electrónico"
            type="email"
            {...register('email', {
              required: 'Este campo es requerido',
              pattern: {
                value: /^\S+@\S+$/i,
                message: 'Correo electrónico inválido',
              },
            })}
            error={errors.email}
          />
        </div>

        <div className="col-12">
          <Input
            label="Contraseña"
            placeholder="Contraseña"
            type="password"
            {...register('password', { required: 'Este campo es requerido' })}
            error={errors.password}
          />
        </div>

        <div className="col-12">
          <Input
            label="Confirmar contraseña"
            placeholder="Confirmar contraseña"
            type="password"
            {...register('password2', {
              required: 'Este campo es requerido',
              validate: value => value === watch('password') || 'La contraseña no coincide',
            })}
            error={errors.password2}
          />
        </div>

        <div className={`col-12 ${styles['terminos']}`}>
          <a href={process.env.REACT_APP_PRIVACIDAD} target="_blank" rel="noopener noreferrer">
            Leer Aviso de Privacidad.
          </a>
          <button type="button" className="btn btn-link" onClick={toggleTerminos}>
            <FontAwesomeIcon icon={terminos ? faCheckSquare : faSquare} />
            <span>He leído y acepto el Aviso de Privacidad</span>
          </button>
        </div>
      </form>

      <button type="submit" disabled={!terminos} form="formLogin" className={`btn btn-une ${styles['btn-submit']}`}>
        Registrarse
      </button>
    </div>
  );
};

FormRegister.propTypes = {
  onAfterSubmit: PropTypes.func,
  alert: PropTypes.func,
};

FormRegister.defaultProps = {
  onAfterSubmit: f => f,
  alert: f => f,
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    alert: alert => dispatch(actions.alert(alert)),
  }),
)(FormRegister);
