import React from 'react';

const useModal = (show = false) => {
  const [showModal, setShowModal] = React.useState(show);
  // const toggleModall = () => setShowModal(prevState => !prevState);
  const toggleModal = React.useCallback(() => setShowModal(prevState => !prevState), []);

  React.useEffect(() => {
    setShowModal(show);
  }, [show]);

  return [showModal, toggleModal];
}

export default useModal;
