import React from 'react';
import PropTypes from 'prop-types';
import ModalBase from '../modalBase/modalBase';
import { isMobile } from 'react-device-detect';
import { isIFrame } from '#utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faStreetView } from '@fortawesome/free-solid-svg-icons';
import StreetMapsLink from '../../random/streetMapsLink/streetMapLink';
import FechaEnTexto from '../../random/fechaEnTexto/fechaEnTexto';
import styles from './infoStop.module.css';
import { roAPI } from '#utils/axiosAPI';

const InfoStopModal = ({ showModal, toggleModal, stop, routes, route }) => {
  const [time, setTime] = React.useState(null);

  React.useEffect(() => {
    async function getTime() {
      try {
        const res = await roAPI.get(`/stops/time/${route.group_id}/${stop.codigo}`);
        setTime(res);
      } catch (error) {
        //
      }
    }

    let interval;
    if (stop.codigo) {
      getTime();
      interval = setInterval(() => {
        if (stop.codigo) {
          getTime();
        }
      }, 30000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [stop, route]);

  const lines = React.useMemo(() => {
    if (stop.codigo && showModal) {
      return stop.lineas.map(l => routes.find(x => x.group_id === l));
    }
    return [];
  }, [stop, showModal, routes]);

  return (
    <div>
      <ModalBase
        show={showModal}
        closeModal={toggleModal}
        headerStyle={{ paddingTop: '0' }}
        cardStyle={isMobile ? {} : { width: isIFrame() ? '40%' : '30%' }}
      >
        <div className="row">
          <div className="col-12">
            <p className={styles['header']}>
              <FontAwesomeIcon icon={faInfoCircle} />
              <span>Información de parada [{stop.codigo}]</span>
            </p>
            <div className={styles['div']} />
            <StreetMapsLink
              customClass={styles['link']}
              text={
                <>
                  <FontAwesomeIcon icon={faStreetView} style={{ marginRight: '.5rem' }} /> <span>{stop.name}</span>
                </>
              }
              lat={stop.latitude}
              lng={stop.longitude}
            />
            <div className={styles['div']} />
            {time && (
              <div className={styles['time']}>
                <FechaEnTexto fecha={new Date().getTime() + time.time * 1000} />
                <span>Tiempo estimado de arribo</span>
              </div>
            )}
            <div className={styles['div']} />
            <div className={`item-desc ${styles['lines']}`}>
              <span className="subtitle">Líneas de parada</span>
              <span className="value">
                <ul>
                  {lines.map(line => {
                    if (line) {
                      return <li key={line.id_route}>{line.name}</li>;
                    }
                    return null;
                  })}
                </ul>
              </span>
            </div>
          </div>
        </div>
      </ModalBase>
    </div>
  );
};

InfoStopModal.propTypes = {
  showModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  stop: PropTypes.any,
  routes: PropTypes.array,
  route: PropTypes.any,
};

InfoStopModal.defaultProps = {
  showModal: false,
  toggleModal: f => f,
  stop: {},
  routes: [],
  route: {},
};

export default InfoStopModal;
