import React from 'react';
import PropTypes from 'prop-types';
import ModalBase from '../modalBase/modalBase';
import { isMobile } from 'react-device-detect';
import { isIFrame } from '#utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faStreetView } from '@fortawesome/free-solid-svg-icons';
import styles from './infoPOI.module.css';
import StreetMapsLink from '../../random/streetMapsLink/streetMapLink';

const InfoPOIModal = ({ showModal, toggleModal, poi }) => {
  return (
    <div>
      <ModalBase
        show={showModal}
        closeModal={toggleModal}
        headerStyle={{ paddingTop: '0' }}
        cardStyle={isMobile ? {} : { width: isIFrame() ? '40%' : '30%' }}
      >
        <div className="row">
          <div className="col-12">
            <p className={styles['header']}>
              <FontAwesomeIcon icon={faInfoCircle} />
              <span>Información de POI</span>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="item-desc">
              <span className="subtitle">Nombre</span>
              <span className="value">{poi.name}</span>
            </div>
          </div>
          <div className="col-12">
            <div className="item-desc">
              <span className="subtitle">Dirección</span>
              <span className="value">
                <StreetMapsLink
                  customClass={styles['link']}
                  text={
                    <>
                      <FontAwesomeIcon icon={faStreetView} style={{ marginRight: '.5rem' }} /> <span>{poi.address}</span>
                    </>
                  }
                  lat={poi.latitude}
                  lng={poi.longitude}
                />
              </span>
            </div>
          </div>
          <div className="col-12">
            <div className="item-desc">
              <span className="subtitle">Descripción</span>
              <span className="value">{poi.description}</span>
            </div>
          </div>
          {poi.image && (
            <div className="col-12">
              <div className={styles['image-wrapper']}>
                <img src={`${process.env.REACT_APP_IMG_POIS}/${poi.image}`} alt="POI" />
              </div>
            </div>
          )}
        </div>
      </ModalBase>
    </div>
  );
};

InfoPOIModal.propTypes = {
  showModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  poi: PropTypes.any,
};

InfoPOIModal.defaultProps = {
  showModal: false,
  toggleModal: f => f,
  poi: {},
};

export default InfoPOIModal;
