import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ModalBase from '../modalBase/modalBase';
import { isMobile } from 'react-device-detect';
import { isIFrame } from '#utils/utils';
import moment from 'moment';
import 'moment/locale/es';
import styles from './infoRoute.module.css';

const InfoRouteModal = ({ showModal, toggleModal, route, showFooter }) => {
  const metadata = route.metadata || {};

  const logo = React.useMemo(() => {
    if (route.logo) {
      return `${process.env.REACT_APP_IMG_ROUTE}/${route.logo}`;
    } else if (route.metadata && route.metadata.logo && route.metadata.logo.includes('http')) {
      return route.metadata.logo;
    }
    return null;
  }, [route]);

  return (
    <div data-testid="InfoRouteModal">
      <ModalBase
        show={showModal}
        closeModal={toggleModal}
        headerStyle={{ paddingTop: '0' }}
        cardStyle={!isMobile && isIFrame() ? { width: '75%' } : {}}
      >
        <div className="row">
          <div className="col-12">
            <p className={styles['header']}>
              <FontAwesomeIcon icon={faInfoCircle} />
              <span>{route.name}</span>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-4">
            <div className="item-desc">
              <span className="subtitle">Recorrido</span>
              <span className="value">Duración: {metadata.duration} horas</span>
              <span className="value">Longitud: {metadata.distance / 1000} km</span>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="item-desc">
              <span className="subtitle">Unidades en servicio</span>
              <span className="value">Lun - Vie: {metadata.service_units} unidades</span>
              <span className="value">Sábado: {metadata.service_units_saturday} unidades</span>
              <span className="value">Domingo: {metadata.service_units_sunday} unidades</span>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="item-desc">
              <span className="subtitle">Frecuencia de paso</span>
              <span className="value">Lun - Vie: {metadata.service_frequency ? metadata.service_frequency.substring(0, 5) : '00:00'}</span>
              <span className="value">
                Sábado: {metadata.service_frequency_saturday ? metadata.service_frequency_saturday.substring(0, 5) : '00:00'}
              </span>
              <span className="value">
                Domingo: {metadata.service_frequency_sunday ? metadata.service_frequency_sunday.substring(0, 5) : '00:00'}
              </span>
            </div>
          </div>

          <div className="col-12 col-md-8">
            <div className="item-desc">
              <span className="subtitle">Horario de servicio</span>
              <span className="value">
                Lun - Vie: {moment(metadata.service_start, 'HH:mm:ss').format('hh:mm a')} -{' '}
                {moment(metadata.service_end, 'HH:mm:ss').format('hh:mm a')}
              </span>
              <span className="value">
                Sábado: {moment(metadata.service_start_saturday, 'HH:mm:ss').format('hh:mm a')} -{' '}
                {moment(metadata.service_end_saturday, 'HH:mm:ss').format('hh:mm a')}
              </span>
              <span className="value">
                Domingo: {moment(metadata.service_start_sunday, 'HH:mm:ss').format('hh:mm a')} -{' '}
                {moment(metadata.service_end_sunday, 'HH:mm:ss').format('hh:mm a')}
              </span>
            </div>
          </div>
        </div>

        {showFooter && (
          <React.Fragment>
            <div className="row">
              <div className="col-12">
                <p className={styles['description']}>
                  La información de las unidades del servicio transporte urbano en el mapa es una aproximación del lugar geográfico por
                  donde circulan.
                </p>
              </div>
            </div>

            {logo && (
              <div className="row justify-content-center">
                <div className="col-12 col-md-6">
                  <img src={logo} alt="Logo" className={styles['logo']} />
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </ModalBase>
    </div>
  );
};

InfoRouteModal.propTypes = {
  showModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  route: PropTypes.any,
  showFooter: PropTypes.bool,
};

InfoRouteModal.defaultProps = {
  showModal: false,
  toggleModal: f => f,
  route: {},
  showFooter: true,
};

export default InfoRouteModal;
