import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import FacebookLogin from 'react-facebook-login';

const FacebookAuth = ({ text, onFacebookLogin }) => {
  return (
    <FacebookLogin
      appId="876862222671792"
      callback={onFacebookLogin}
      autoLoad={false}
      size="small"
      textButton={text}
      cssClass="btn btn-fb"
      disableMobileRedirect={true}
      icon={<FontAwesomeIcon icon={faFacebookSquare} />}
    />
  );
};

FacebookAuth.propTypes = {
  text: PropTypes.string,
  onFacebookLogin: PropTypes.func,
};

FacebookAuth.defaultProps = {
  text: 'Continuar con Facebook',
  onFacebookLogin: f => f,
};

export default FacebookAuth;