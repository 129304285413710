import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faHome, faRoad, faCity, faMapMarkedAlt, faTrafficLight, faCrosshairs } from '@fortawesome/free-solid-svg-icons';
import useDebounce from '#hooks/useDebounce';
import useClickOutside from '#hooks/useClickOutside';
import { roAPI } from '#utils/axiosAPI';
import styles from './azurePlaces.module.css';

const AzurePlaces = ({ placeholder, icon, iconColor, reduxPlace, onPlaceSelected, onCrosshairs }) => {
  const [query, setQuery] = React.useState('');
  const [cursor, setCursor] = React.useState(-1);
  const [suggestions, setSuggestions] = React.useState([]);
  const [place, setPlace] = React.useState(reduxPlace);
  const [placeName, setPlaceName] = React.useState(null);

  const debouncedQuery = useDebounce(query, 300);
  const suggestionsRef = React.useRef();
  const clickRef = useClickOutside(suggestions.length > 0, () => {
    setQuery('');
    setSuggestions([]);
  });

  const searchPlace = React.useCallback(async query => {
    try {
      const resp = await roAPI('/places/fuzzy', {
        params: {
          query,
          limit: 5,
          idxSet: 'PAD,POI',
        },
      });
      setSuggestions(resp.results);
    } catch (error) {
      console.error(error);
    }
  }, []);

  React.useEffect(() => {
    if (query !== debouncedQuery || debouncedQuery === placeName) {
      return;
    }
    if (debouncedQuery.length < 4) {
      setSuggestions([]);
      setPlace(null);
      setPlaceName(null);
    } else {
      searchPlace(debouncedQuery);
    }
  }, [query, debouncedQuery, searchPlace, placeName]);

  React.useEffect(() => {
    if (place && place.type === 'POI') {
      setQuery(place.poi.name);
      setPlaceName(place.poi.name);
    } else if (place) {
      setQuery(place.address.freeformAddress);
      setPlaceName(place.address.freeformAddress);
    }
    setSuggestions([]);
  }, [place]);

  React.useEffect(() => {
    setPlace(reduxPlace);
  }, [reduxPlace]);

  const handleQueryChange = event => {
    const {
      target: { value },
    } = event;
    setQuery(value);
    setCursor(-1);
  };

  const handleKeys = event => {
    if (suggestions.length === 0 || !suggestionsRef.current) {
      return;
    }
    const ul = suggestionsRef.current;
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        if (cursor === -1) {
          ul.childNodes[0].focus();
          setCursor(0);
        } else if (cursor + 1 < ul.childNodes.length) {
          ul.childNodes[cursor + 1].focus();
          setCursor(prevState => prevState + 1);
        }
        break;
      case 'ArrowUp':
        event.preventDefault();
        if (cursor > 0) {
          if (cursor - 1 >= 0) {
            ul.childNodes[cursor - 1].focus();
            setCursor(prevState => prevState - 1);
          }
        }
        break;
      case 'Enter':
        if (cursor !== -1) {
          ul.childNodes[cursor].click();
        }
        break;
      default:
        break;
    }
  };

  const handleBlur = () => {
    if (place) {
      // TODO: se arruina si te mueves a ver suggestions
      setQuery(placeName);
    }
  };

  const onPlaceSelect = place => {
    setPlace(place);
    onPlaceSelected(place);
  };

  return (
    <div data-testid="AzurePlaces" ref={clickRef} className={styles['azureplaces']}>
      <div className={`${styles['input-wrapper']} mirai-shadow`}>
        <div className={styles['icon']} style={{ backgroundColor: iconColor }}>
          <div className={styles['icon-circle']}>
            <FontAwesomeIcon icon={icon} />
          </div>
        </div>
        <div className={styles['input']}>
          <input
            type="text"
            className=""
            placeholder={placeholder}
            value={query}
            onChange={handleQueryChange}
            onKeyDown={handleKeys}
            onBlur={handleBlur}
            onClick={e => e.target.select()}
          />
        </div>
        <button type="button" className={styles['crosshair']} onClick={onCrosshairs}>
          <FontAwesomeIcon icon={faCrosshairs} />
        </button>
      </div>
      {suggestions.length > 0 && (
        <Suggestions ref={suggestionsRef} suggestions={suggestions} handleKeys={handleKeys} onSelect={onPlaceSelect} />
      )}
    </div>
  );
};

AzurePlaces.propTypes = {
  placeholder: PropTypes.string,
  icon: PropTypes.any,
  iconColor: PropTypes.string,
  onPlaceSelected: PropTypes.func,
  reduxPlace: PropTypes.any,
  onCrosshairs: PropTypes.func,
};

AzurePlaces.defaultProps = {
  placeholder: 'El placeholder',
  icon: null,
  iconColor: '#fff',
  onPlaceSelected: f => f,
  reduxPlace: null,
  onCrosshairs: f => f,
};

export default AzurePlaces;

/** **************************************** */
/** Suggestions list */
/** **************************************** */

const placeTypes = {
  POI: {
    text: 'Punto de interés',
    icon: faMapMarkerAlt,
  },
  Street: {
    text: 'Calle',
    icon: faRoad,
  },
  Geography: {
    text: 'Entidad',
    icon: faCity,
  },
  'Point Address': {
    text: 'Domicilio',
    icon: faHome,
  },
  'Address Range': {
    text: 'Rango de dirección',
    icon: faMapMarkedAlt,
  },
  'Cross Street': {
    text: 'Cruce de calles',
    icon: faTrafficLight,
  },
};

/* eslint-disable react/display-name */
const Suggestions = React.forwardRef(({ suggestions, handleKeys, onSelect }, ref) => {
  const list = suggestions.map(place => (
    <li key={place.id} tabIndex="-1" onKeyDown={handleKeys} onClick={() => onSelect(place)}>
      <div className={styles['list-icon']}>
        <FontAwesomeIcon icon={placeTypes[place.type].icon} />
      </div>
      <div className={styles['list-text']}>
        {place.type === 'POI' && <span className={styles['poi']}>{place.poi.name}</span>}
        <span className={styles['address']}>{place.address.freeformAddress}</span>
        <span className={styles['type']}>Tipo: {placeTypes[place.type].text}</span>
      </div>
    </li>
  ));

  return (
    <div className={`${styles['suggestions']} mirai-shadow`}>
      <ul ref={ref}>{list}</ul>
    </div>
  );
});
/* eslint-enable react/display-name */

Suggestions.propTypes = {
  suggestions: PropTypes.array,
  handleKeys: PropTypes.func,
  onSelect: PropTypes.func,
};

Suggestions.defaultProps = {
  suggestions: [],
  handleKeys: f => f,
  onSelect: f => f,
};
