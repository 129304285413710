import React from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import { isIFrame } from '#utils/utils';

const defaultOptions = {
  browser: {
    backgroundStyle: {},
    backgroundClass: 'background',
    containerStyle: {},
    containerClass: 'container',
    rowStyle: {},
    rowClass: 'row',
    colStyle: {},
    colClass: 'col-12'
  },
  mobile: {
    wrapperStyle: {},
    wrapperClass: 'mobileWrapper',
  },
};

function LayoutOnWidth(WrappedComponent, customOptions = defaultOptions, ignoreIFrame = false) {
  const options = {
    browser: { ...defaultOptions.browser, ...customOptions.browser },
    mobile: { ...defaultOptions.mobile, ...customOptions.mobile },
  };
  const { browser, mobile } = options;

  return function component() {
    if (!ignoreIFrame && isIFrame()) {
      return <WrappedComponent />;
    } else if (isBrowser) {
      return (
        <div className={browser.backgroundClass} style={browser.backgroundStyle}>
          <div className={browser.containerClass} style={browser.containerStyle}>
            <div className={browser.rowClass} style={browser.rowStyle}>
              <div className={browser.colClass} style={browser.colStyle}>
                <WrappedComponent />
              </div>
            </div>
          </div>
        </div>
      );
    } else if (isMobile) {
      return (
        <div data-testid="MobileLayout" className={mobile.wrapperClass} style={mobile.wrapperStyle}>
          <WrappedComponent />
        </div>
      );
    } else {
      return <div>Unsupported device</div>;
    }
  };
}

export default LayoutOnWidth;
