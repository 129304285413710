import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { getToken, isTokenExpired, refreshToken } from './authService';
import { dispatchByErrorType } from '#redux/axiosDispatch';
import knownErrors from './customErrors.json';

export const beaAPI = axios.create({
  baseURL: process.env.REACT_APP_API_BEA,
});

beaAPI.interceptors.response.use(
  async response => {
    if (response.headers['content-type'].indexOf('application/json') !== -1) {
      return response.data;
    }
    return response;
  },
  error => {
    if ((error.response && error.response.status !== 500) || !error.response) {
      Sentry.captureException(error);
    }
    return Promise.reject(error);
  },
);

export const roAPI = axios.create({
  baseURL: process.env.REACT_APP_API_RO,
});

async function settingToken() {
  // Getting token
  let token = getToken();
  if (isTokenExpired(token)) {
    token = await refreshToken();
  }
  return token;
}

roAPI.interceptors.request.use(async config => {
  const token = await settingToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

roAPI.interceptors.response.use(
  async response => {
    if (response.headers['content-type'].indexOf('application/json') !== -1) {
      return response.data;
    }
    return response;
  },
  error => {
    if (error.response && error.response.status !== 500) {
      // The reques was made and the server responded with a status code.
      const {
        data: { type },
      } = error.response;
      dispatchByErrorType(type);
    } else if (error.request) {
      // The request was made but no response was received
      dispatchByErrorType('internet');
    } else {
      // Something happened and the request failed.
      dispatchByErrorType('default');
    }

    // Sending error to Sentry.
    if (
      (error.response && knownErrors[error.response.data.type]) ||
      (error.request && error.message === 'Network Error')
    ) {
      // Sentry.clearContext();
    } else {
      Sentry.captureException(error);
    }

    // Sending error to calling block.
    return Promise.reject(error);
  },
);
