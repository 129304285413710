import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { roAPI } from '#utils/axiosAPI';

const ActivateAccount = ({ onAfterSubmit, alert }) => {
  const { email, code } = useParams();

  React.useEffect(() => {
    async function validate() {
      try {
        await roAPI.post('/auth/activation/email', {
          email: decodeURIComponent(email),
          code: decodeURIComponent(code),
        });
        onAfterSubmit('/auth/inicio', 'redirect');
        alert({
          type: 'success',
          title: '¡Verificación exitosa!',
          message: 'Por favor inicia sesión y disfrute del Sistema Integral de Transporte.',
        });
      } catch (error) {
        onAfterSubmit('/auth/activate', 'redirect');
        alert({
          type: 'danger',
          title: '¡Código vencido!',
          message: 'El código ya no es válido, por favor solicite otro.',
        });
      }
    }
    validate();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <></>;
};

ActivateAccount.propTypes = {
  onAfterSubmit: PropTypes.func,
  alert: PropTypes.func,
};

ActivateAccount.defaulProps = {
  onAfterSubmit: f => f,
  alert: f => f,
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    alert: alert => dispatch(actions.alert(alert)),
  }),
)(ActivateAccount);
