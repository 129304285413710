import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import ModalBase from '../modalBase/modalBase';
import styles from './terms.module.css';

const logo = require('#assets/images/logo.png');

const TermsModal = ({ showModal, toggleModal, onAccept }) => (
  <div data-testid="TermsModal">
    <ModalBase
      show={showModal}
      closeModal={toggleModal}
      closeOnBackdrop={false}
      cardStyle={isMobile ? { height: 'auto' } : { width: '40%' }}
    >

      <div className="row">
        <div className="col-12">
          <img src={logo} alt="Logo" className={styles['logo']} />
          <h5 className={styles['title']}>Terminos y condiciones de uso</h5>
          <p>
            Para usar la aplicación de Transporte UNE debes de aceptar los términos y condiciones de uso.
          </p>
          <a href={process.env.REACT_APP_PRIVACIDAD} target="_blank" rel="noopener noreferrer" className={styles['link']}>
            <FontAwesomeIcon icon={faLink} style={{ marginRight: '.5rem' }} />
            Leer Aviso de Privacidad
          </a>
          <button type="button" className={`btn btn-une ${styles['button']}`} onClick={onAccept}>
            He leído y acepto el Aviso de Privacidad
          </button>
        </div>
      </div>
    </ModalBase>
  </div>
);

TermsModal.propTypes = {
  showModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  onAccept: PropTypes.func,
};

TermsModal.defaultProps = {
  showModal: false,
  toggleModal: f => f,
  onAccept: f => f,
};

export default TermsModal;
