import { createStore, combineReducers, applyMiddleware } from 'redux';
import { city, cities, routes, panelsidebarToggle, alerts, loading, terms, trip } from './reducers';

export const initialState = {
  terms: false,
  cities: [],
  city: '1',
  routes: [],
  panelsidebarToggle: false,
  alerts: [],
  loading: [],
  trip: {
    origin: null,
    destination: null,
    routes: [],
    selectedTrip: 0,
  }
};

// Tries to load state from localstorage
const stateFromStorage = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return initialState;
    }
    const savedState = JSON.parse(serializedState);
    return {
      ...initialState,
      ...savedState,
    };
  } catch (error) {
    return initialState;
  }
};

// Saves state changes to localstorage
const saveToStorage = store => next => action => {
  try {
    const result = next(action);
    // Saving some state to storage
    switch (action.type) {
      // Selective state
      case 'CITY_CHANGE':
      case 'UPDATE_ROUTES':
      case 'PANELSIDEBAR_TOGGLE':
      case 'TERMS_ACCEPT': {
        const currentState = store.getState();
        const stateToSave = {
          terms: currentState.terms,
          city: currentState.city,
          routes: currentState.routes,
          panelsidebarToggle: currentState.panelsidebarToggle,
        };
        const serializedState = JSON.stringify(stateToSave);
        localStorage.setItem('state', serializedState);
        break;
      }
      default: break;
    }
    // Continue
    return result;
  } catch (error) {
    // Something.
  }
};

const store = createStore(
  combineReducers({ city, cities, routes, panelsidebarToggle, alerts, loading, terms, trip }),
  stateFromStorage(),
  applyMiddleware(saveToStorage),
);

export default store;
