import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { isMobile, isSafari } from 'react-device-detect';
import { connect as reduxConnect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInbox, faSignInAlt, faSignOutAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import useClickOutside from '#hooks/useClickOutside';
import { getToken, logout } from '#utils/authService';
import styles from './mapSidebar.module.css'
import { faMap } from '@fortawesome/free-regular-svg-icons';
import { actions } from '#redux/reducers';

const logo = require('#assets/images/logo.png');

const MapSidebar = ({ show, close, showLogo, forceMinWidth, clearTrip }) => {
  const clickRef = useClickOutside(show, close);
  const token = React.useRef(getToken());

  const handleLogout = () => {
    logout();
    clearTrip();
  };

  return (
    <div data-testid="MapSidebar" ref={clickRef} className={`${styles['sidebar']} ${!show && styles['show']} ${isSafari && styles['safari']} ${forceMinWidth && styles['minwidth']}`}>
      <div className={styles['header']}>
        {(isMobile || showLogo) && (
          <img src={logo} alt="Logo UNE" className={styles['header-logo']} />
        )}
      </div>
      <div className={styles['body']}>
        <ul className={styles['body-list']}>
          {token.current && (
            <li>
              <NavLink to="/perfil" activeClassName={styles['active']}>
                <FontAwesomeIcon icon={faUserCircle} />
                <span>Mi perfil</span>
              </NavLink>
            </li>
          )}
          <li>
            <NavLink to="/notificaciones" activeClassName={styles['active']}>
              <FontAwesomeIcon icon={faInbox} />
              <span>Notificaciones</span>
            </NavLink>
          </li>
          <li>
            <a href="https://api.whatsapp.com/send?phone=526621047755&text=Buen%20d%C3%ADa,%20deseo%20realizar%20un%20reporte" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faWhatsapp} />
              <span>Atención</span>
            </a>
          </li>
          <li>
            <NavLink to="/rutas" activeClassName={styles['active']}>
              <FontAwesomeIcon icon={faMap} />
              <span>Mapa</span>
            </NavLink>
          </li>
        </ul>
        {!token.current && (
          <NavLink to="/auth/inicio" className={`btn btn-success ${styles['login']}`}>
            <FontAwesomeIcon icon={faSignInAlt} style={{ marginRight: '0.5rem' }} />
            Iniciar sesión
          </NavLink>
        )}
        {token.current && (
          <NavLink to="/" className={`btn btn-link ${styles['logout']}`} onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '0.5rem' }} />
            Cerrar sesión
          </NavLink>
        )}
      </div>
    </div>
  );
};

MapSidebar.propTypes = {
  show: PropTypes.bool,
  close: PropTypes.func,
  showLogo: PropTypes.bool,
  forceMinWidth: PropTypes.bool,
  clearTrip: PropTypes.func,
};

MapSidebar.defaultProps = {
  show: false,
  close: f => f,
  showLogo: false,
  forceMinWidth: false,
  clearTrip: f => f,
};

export default reduxConnect(
  () => ({}),
  dispatch => ({
    clearTrip: () => dispatch(actions.tripClearTrip()),
  }),
)(MapSidebar);
