import React from 'react';
import PropTypes from 'prop-types';
import styles from './alert.module.css';
import { isMobile } from 'react-device-detect';

const Alert = ({ uuid, type, title, message, timer, removeAlert }) => {
  const [animation, setAnimation] = React.useState('animate-in');

  React.useEffect(() => {
    function removeByTimer() {
      setAnimation('animate-out');
      setTimeout(() => {
        removeAlert(uuid);
      }, 400);
    }

    let timeout;
    if (timer && animation === 'animate-in') {
      timeout = setTimeout(removeByTimer, timer);
    }
    return () => clearTimeout(timeout);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div data-testid="Alert" className={`alert alert-dismissible alert-${type} ${styles['alert']} ${animation} ${isMobile && styles['mobile']}`}>
      <h5>{title}</h5>
      <span>{message}</span>
      <button type="button" className="close" onClick={() => { removeAlert(uuid); }}>
        <span>&times;</span>
      </button>
    </div>
  );
};

Alert.propTypes = {
  uuid: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  timer: PropTypes.number,
  removeAlert: PropTypes.func,
};

Alert.defaultProps = {
  uuid: '123',
  type: 'dark',
  title: 'Alerta',
  message: 'Mensaje de alerta',
  timer: 0,
  removeAlert: f => f,
};

export default Alert;