import React from 'react';
import of from 'await-of';
import { roAPI } from '#utils/axiosAPI';
import { useDispatch } from 'react-redux';

const useUpdateCities = (updateCities, disabled = true) => {
  const dispatch = useDispatch();

  const update = React.useMemo(() => {
    if (!updateCities) {
      return cities => {
        dispatch({
          type: 'CITIES_UPDATE',
          payload: cities,
        });
      };
    }
    return updateCities;
  }, [updateCities, dispatch]);

  const loading = React.useMemo(
    () => ({
      set: () => dispatch({ type: 'LOADING_ADD' }),
      stop: () => dispatch({ type: 'LOADING_REMOVE' }),
    }),
    [dispatch],
  );

  React.useEffect(() => {
    async function fetchCities() {
      loading.set();
      const [res, err] = await of(
        roAPI.get('/cities', {
          params: {
            ...(disabled && { disabled: false }),
          },
        }),
      );
      if (!err) {
        update(res.rows);
      }
      loading.stop();
    }
    fetchCities();
  }, [update, disabled, loading]);
  return null;
};

export default useUpdateCities;
