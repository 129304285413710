import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { roAPI } from '#utils/axiosAPI';
import styles from './notificationList.module.css';
import useUpdateCities from '#hooks/useUpdateCities';

const NotificationList = ({ cities, updateCities, loading }) => {
  const [notifications, setNotifications] = React.useState([]);
  const [filters, setFilters] = React.useState({
    types: {
      importante: true,
      general: true,
    },
    cities: cities.map(x => x.id_city),
  });

  React.useEffect(() => {
    setFilters(prev => ({
      ...prev,
      cities: cities.map(x => x.id_city),
    }));
  }, [cities]);

  useUpdateCities(updateCities, loading);

  React.useEffect(() => {
    async function getData() {
      try {
        const topic = filters.cities
          .map(x => {
            const filter = [];
            if (filters.types.general) {
              filter.push(`avisos_${x}_general`);
            }
            if (filters.types.importante) {
              filter.push(`avisos_${x}_importante`);
            }
            return filter.join(',');
          })
          .filter(x => x)
          .join(',');

        if (topic) {
          const resp = await roAPI.get('/notifications', {
            params: {
              limit: 999999,
              page: 1,
              topic,
            },
          });
          setNotifications(resp.rows);
        }
      } catch (error) {
        //
      }
    }
    getData();
  }, [filters]);

  const toggleTypes = type => {
    setFilters(prev => ({
      ...prev,
      types: {
        ...prev.types,
        [type]: !prev.types[type],
      },
    }));
  };

  const toggleCities = city => {
    setFilters(prev => {
      const dummy = [...prev.cities];
      const index = dummy.findIndex(x => x === city);
      if (index !== -1) {
        dummy.splice(index, 1);
      } else {
        dummy.push(city);
      }
      return {
        ...prev,
        cities: dummy,
      };
    });
  };

  return (
    <div>
      <div className={`${styles['checks']} ${isMobile && styles['checks-mobile']}`}>
        <button type="button" className="btn btn-link" onClick={() => toggleTypes('general')}>
          <FontAwesomeIcon icon={filters.types.general ? faCheckSquare : faSquare} style={{ marginRight: '.5rem' }} />
          Avisos generales
        </button>
        <button type="button" className="btn btn-link" onClick={() => toggleTypes('importante')}>
          <FontAwesomeIcon
            icon={filters.types.importante ? faCheckSquare : faSquare}
            style={{ marginRight: '.5rem' }}
          />
          Avisos importantes
        </button>
      </div>
      <div className={`${styles['checks']} ${isMobile && styles['checks-mobile']}`}>
        {cities.map(city => (
          <button key={city.id_city} type="button" className="btn btn-link" onClick={() => toggleCities(city.id_city)}>
            <FontAwesomeIcon
              icon={filters.cities.includes(city.id_city) ? faCheckSquare : faSquare}
              style={{ marginRight: '.5rem' }}
            />
            {city.name}
          </button>
        ))}
      </div>
      <ul className={styles['list']}>
        {notifications.map(n => (
          <li key={n.id_notification}>
            <div className="card mirai-shadow">
              <div className="card-body">
                <div className={styles['n-header']}>
                  <span>{n.title}</span>
                  <span>{moment(n.created_at).format('DD MMMM YYYY hh:mm a')}</span>
                </div>
                <p className={styles['n-msg']}>{n.message}</p>
              </div>
            </div>
          </li>
        ))}
        {!notifications.length && (
          <div className="card mirai-shadow">
            <div className="card-body">
              <div className={styles['n-header']}>
                <span>Lo sentimos</span>
              </div>
              <p className={styles['n-msg']}>No existen notificaciones que cumplan con estas condiciones.</p>
            </div>
          </div>
        )}
      </ul>
    </div>
  );
};

NotificationList.propTypes = {
  cities: PropTypes.array,
  updateCities: PropTypes.func,
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

NotificationList.defaultProps = {
  cities: [],
  updateCities: f => f,
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  state => ({
    cities: state.cities,
  }),
  dispatch => ({
    updateCities: cities => dispatch(actions.citiesUpdate(cities)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(NotificationList);
