import React from 'react';
import PropTypes from 'prop-types';
import { isBrowser, isMobile } from 'react-device-detect';
import { Redirect } from 'react-router-dom';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import { isIFrame } from '#utils/utils';
import LayoutOnWidth from '#components/HOCs/layoutOnWidth/layoutOnWidth';
import CityCard from '#components/cards/cityCard/cityCard';
import styles from './citySelector.module.css';

const logo = require('#assets/images/logo.png');
const iconHmo = require('#assets/images/iconos/ciudades/icon-w-hillo.png');
const iconOb = require('#assets/images/iconos/ciudades/icon-w-obregon.png');
const iconNav = require('#assets/images/iconos/ciudades/icon-w-navojoa.png');
const iconGuay = require('#assets/images/iconos/ciudades/icon-w-guaymas.png');

const CitySelector = ({ city, changeCity }) => {
  if (city) { return <Redirect to="/rutas" />; }

  return (
    <div data-testid="citySelector" className={`card mirai-shadow ${isBrowser && styles['card']} ${isMobile && styles['card-mobile']}`}>
      <div className={`card-body ${isMobile && styles['card-body']}`}>
        <div className="row">
          <div className="col-12">
            <img src={logo} alt="Logo UNE" className={styles['logo']} />
            <p className={styles['subtitle']}>¡Bienvenido al sistema de transporte UNE!</p>
          </div>
        </div>
      </div>
      <div className={`${styles['selector']} ${isMobile && styles['selector-mobile']}`}>
        <div className={styles['selector-background']}></div>
        <div data-testid="citySelector-cities" className={`${isBrowser && styles['cities']} ${isMobile && styles['cities-mobile']}`}>
          <div className="row">
            <div className="col-6">
              <CityCard
                name="Hermosillo"
                bgColor="#E94D19"
                imgSrc={iconHmo}
                onCardClick={() => changeCity(1)}
              />
            </div>
            <div className="col-6">
              <CityCard
                name="Ciudad Obregón"
                bgColor="#66CA21"
                imgSrc={iconOb}
                onCardClick={() => changeCity(2)}
              />
            </div>
            <div className="col-6">
              <CityCard
                name="Navojoa"
                bgColor="#FF821B"
                imgSrc={iconNav}
                onCardClick={() => changeCity(3)}
              />
            </div>
            <div className="col-6">
              <CityCard
                name="Guaymas"
                bgColor="#0CAFB7"
                imgSrc={iconGuay}
                onCardClick={() => changeCity(4)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CitySelector.propTypes = {
  city: PropTypes.string,
  changeCity: PropTypes.func,
};

CitySelector.defaultProps = {
  city: '1',
  changeCity: f => f,
};

const ConnectedCitySelector = reduxConnect(
  state => ({
    city: state.city,
  }),
  dispatch => ({
    changeCity: (city) => dispatch(actions.changeCity(city))
  }),
)(CitySelector);

export default LayoutOnWidth(ConnectedCitySelector, {
  browser: {
    backgroundStyle: {
      backgroundColor: '#f5f5f5',
      height: '100vh',
      color: '#fff',
    },
    containerStyle: {
      height: '100%',
      alignItems: 'center',
    },
    rowStyle: {
      height: '100%',
      alignItems: 'center',
    },
    rowClass: 'row justify-content-center',
    colClass: `col-12 ${isIFrame() ? 'col-md-8' : 'col-md-6'}`
  },
}, true);
