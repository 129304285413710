import React from 'react';
import PropTypes from 'prop-types';
import { connect as ReduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import Alert from '../alert/alert';
import styles from './alertList.module.css';

const AlertList = ({ alerts, alertRemove }) => (
  <ul data-testid="AlertList" className={styles['list']}>
    {alerts.slice(0).reverse().map((alert, i) => {
      if (i > 2) { return null; }
      return <Alert key={alert.uuid} {...alert} removeAlert={alertRemove} />;
    })}
  </ul>
);

AlertList.propTypes = {
  alerts: PropTypes.array,
  alertRemove: PropTypes.func,
};

AlertList.defaultProps = {
  alerts: [],
  alertRemove: f => f,
};

export default ReduxConnect(
  state => ({
    alerts: state.alerts,
  }),
  dispatch => ({
    alertRemove: (uuid) => dispatch(actions.alertRemove(uuid)),
  })
)(AlertList);
