import React from 'react';
import PropTypes from 'prop-types';
import { distanceInWords } from 'date-fns';
import esLocale from 'date-fns/locale/es';

const SecondsInText = ({ seconds }) => (
  <span>{distanceInWords(0, seconds * 1000, {locale: esLocale })}</span>
);

SecondsInText.propTypes = {
  seconds: PropTypes.number,
};

SecondsInText.defaultProps = {
  seconds: 0,
};

export default SecondsInText;
